import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/react';
import Vimeo from '@u-wave/react-vimeo';
import { ThemeContext } from '../components/theme-provider';
import FullImage from '../components/full-image';
import Button from '../components/button';
import ArtistScroller from '../components/artist-scroller';
import PortableText from '../components/portable-text';
import PDF from '../components/pdf';
import Gallery from '../components/gallery';
import Accordion from '../components/accordion';
import Play from '../svg/play.svg';
import { formatDates } from '../utility';

export default function Homepage({ data }) {
  const [time, setTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [player, setPlayer] = useState(null);
  const [paused, setPaused] = useState(true);

  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setGrayFooter(true);
  }, []);
  
  useEffect(() => {
    player?.getDuration().then((duration) => setTotalTime(duration));
  }, [player]);

  function HHMMSS(input) {
    let secNum = parseInt(input, 10);
    let hours   = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - (hours * 3600)) / 60);
    let seconds = secNum - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = '0' + hours; }
    if (minutes < 10) { minutes = '0' + minutes; }
    if (seconds < 10) { seconds = '0' + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }

  return (
    <div
      css={css`
        background-color: var(--white);
        padding-bottom: var(--bigMargin);
      `}
    >
      {data.homepage._rawAcknowledgementText && <div
        css={css`
          position: relative;
          background-color: var(--primary);
          padding: 3rem var(--margin);
          z-index: 2;

          @media (max-width: 1080px) {
            margin-top: 1.5rem;
          }

          @media (max-width: 700px) {
            margin-top: 0;
            padding: 2.5rem 3rem 2rem;
          }

          a:hover {
            color: var(--white);
          }
        `}
      >
        <PortableText
          blocks={data.homepage._rawAcknowledgementText}
        />
      </div>}
      {data.homepage.enableModal && <div
        css={css`
          position: relative;
          color: var(--primary);
          background-color: var(--black);
          padding: 3rem var(--margin);
          z-index: 2;

          @media (max-width: 700px) {
            margin-top: 0;
            padding: 2.5rem 3rem 2rem;
          }

          a:hover {
            color: var(--white);
          }
        `}
      >
        <PortableText
          blocks={data.homepage._rawModalText}
        />
      </div>}
      {data.homepage.featuredPrograms.length > 0 && data.homepage.featuredPrograms
        .map((program, i) => (
          <FullImage
            key={program._id}
            text={`
              ${(program._type === 'program') ? ((program.locationTitle) ? `${program.locationTitle}/\u200b` : `The Substation/\u200b`) : ''}
              ${(program.people?.length > 0) ? `${program.people.map(person => person.name).join(', ')}/\u200b` : ''}
              ${program.title}/\u200b
              ${(program._type === 'program') ? formatDates(program.startDate, program.endDate) + '/' : ''}
            `}
            image={program.featureImage}
            link={`/${(program._type === 'program') ? 'program' : 'publication'}/${program.slug.current}`}
            noMargin
          />
        ))
      }
      <div
        css={css`
          margin: var(--margin) var(--bigMargin);
          margin-bottom: var(--bigMargin);
        `}
      >
        <Button
          text="What's on"
          link="/whats-on"
          large
        />
      </div>
      {data.artists.residentCompanies.length > 0 && <ArtistScroller
        title=<div>Artists/<br />Resident Companies/</div>
        artists={data.artists.residentCompanies}
      />}
      {data.artists.inResidence.length > 0 && <ArtistScroller
        title=<div>Artists/<br />Studio Artists/</div>
        artists={data.artists.inResidence}
      />}
      {data.artists.inDevelopment.length > 0 && <ArtistScroller
        title=<div>Artists/<br />In Development/</div>
        artists={data.artists.inDevelopment}
      />}
      <div
        css={css`
          margin: 0 var(--margin);
          margin-bottom: var(--bigMargin);
        `}
      >
        <Button
          text="All artists"
          link="/artists"
          large
        />
      </div>
      <div
        className="type--45"
        css={css`
          background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--lightGray) 10rem, var(--lightGray) calc(100% - 10rem), rgba(255, 255, 255, 0));
          padding: 15rem var(--margin);
          margin-bottom: var(--bigMargin);
        `}
      >
        <PortableText
          blocks={data.homepage._rawAboutText}
        />
        <div
          className="type--20"
          css={css`
            margin-top: var(--margin);
            margin-bottom: var(--bigMargin);
          `}
        >
          <Link to="/about">
            Learn more about us
          </Link>
        </div>
        {data.homepage.video && <div
          css={css`
            display: grid;
            grid-template-columns: repeat(12, minmax(0, 1fr));
            gap: var(--gutter);
            row-gap: 0;
          `}
        >
          <div
            css={css`
              position: relative;
              grid-column-start: 3;
              grid-column-end: span 8;

              @media (max-width: 1080px) {
                grid-column-start: 1;
                grid-column-end: span 12;
              }

              iframe {
                pointer-events: none;
              }
            `}
          >
            <Vimeo
              video={data.homepage.video}
              controls={false}
              onReady={(player) => setPlayer(player)}
              onTimeUpdate={(time) => { setTime(time.seconds); setTotalTime(time.duration); }}
              onEnd={() => { setPaused(true); setTime(0) }}
              color="FFF"
              showPortrait={false}
              showTitle={false}
              showByline={false}
              responsive
            />
            <div
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: url(${data.homepage.videoImage.asset.gatsbyImageData.images.fallback.src});
                background-size: cover;
                background-position: center center;
                opacity: ${(paused) ? 1 : 0};
                transition: opacity 0.6s var(--curve);
              `}
            >
            </div>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-size: cover;
                background-position: center center;
                cursor: pointer;

                &:hover {
                 svg {
                   path {
                     stroke: var(--primary);
                      transition: stroke 0s;
                   }
                 }
                }

                svg {
                  opacity: ${(paused) ? 1 : 0};
                  transition: opacity 0.6s var(--curve);

                  path {
                    transition: stroke 0.6s var(--curve);
                  }
                }
              `}
              onClick={() => {
                player?.getPaused().then((paused) => {
                  if (paused) {
                    player.play();
                    setPaused(false);
                  } else {
                    player.pause();
                    setPaused(true);
                  }
                });
              }}
            >
              <Play />
            </div>
          </div>
          <div
            className="type--15"
            css={css`
              grid-column-start: 3;
              grid-column-end: span 8;
              height: 2rem;
              margin-top: calc(var(--gutter) * 0.5);
            `}
          >
            {HHMMSS(time)}/{HHMMSS(totalTime)}
          </div>
        </div>}
      </div>
      <div
        className="type--45"
        css={css`
          margin: 0 var(--margin);
        `}
      >
        <PortableText
          blocks={data.homepage._rawCommunityText}
        />
        <div
          className="type--20"
          css={css`
            margin-top: var(--margin);
            margin-bottom: var(--bigMargin);
          `}
        >
          <Link to="/about">
            Learn more
          </Link>
        </div>
        <div>
          {data.homepage.communityImages.length > 0 && <Gallery images={data.homepage.communityImages} />}
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  query {
    homepage: sanityHomepage(_id: { regex: "/(drafts\\\\.)?homepage/" }) {
      id
      _rawAcknowledgementText(resolveReferences: {maxDepth: 10})
      enableModal
      _rawModalText(resolveReferences: {maxDepth: 10})
      featuredPrograms {
        ... on SanityPublication {
          _id
          _type
          slug {
            current
          }
          featureImage {
            ...Image
          }
          title
        }
        ... on SanityProgram {
          _id
          _type
          slug {
            current
          }
          featureImage {
            ...Image
          }
          title
          startDate
          endDate
          people {
            name
            slug {
              current
            }
          }
        }
      }
      _rawAboutText(resolveReferences: {maxDepth: 10})
      video
      videoImage {
        asset {
          gatsbyImageData(width: 1485)
        }
      }
      _rawCommunityText(resolveReferences: {maxDepth: 10})
      communityImages {
        ...Image
        asset {
          description
        }
      }
    }
    artists: sanityArtists(_id: { regex: "/(drafts\\\\.)?artists/" }) {
      id
      residentCompanies {
        slug {
          current
        }
        featureImage {
          ...Image
        }
        type
        name
      }
      inResidence {
        slug {
          current
        }
        featureImage {
          ...Image
        }
        type
        name
      }
      inDevelopment {
        slug {
          current
        }
        featureImage {
          ...Image
        }
        type
        name
      }
    }
    inDevelopmentPrograms: allSanityProgram(sort: {fields: startDate, order: DESC}, filter: {inDevelopment: {eq: true}}) {
      nodes {
        id
        slug {
          current
        }
        format {
          title
          slug {
            current
          }
        }
        location
        featureImage {
          ...Image
        }
        people {
          name
          slug {
            current
          }
        }
        title
        startDate
        endDate
        _rawContent(resolveReferences: {maxDepth: 10})
      }
    }
  }
`

